import { checkAdminRole, checkAuth } from "@/utils/router";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { rootRoute } from "..";

export const dashboardRoute = createRoute({
  path: "dashboard",
  getParentRoute: () => rootRoute,
  beforeLoad: async () => {
    await checkAuth();
    await checkAdminRole();
  },
  component: lazyRouteComponent(() => import("./+Dashboard")),
});
export const usageDashboardRoute = createRoute({
  path: "dashboard/usage",
  getParentRoute: () => rootRoute,
  beforeLoad: async () => {
    await checkAuth();
    await checkAdminRole();
  },
  component: lazyRouteComponent(() => import("./+UsageDashboard")),
});
